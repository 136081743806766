import ImageGallery from "react-image-gallery";
import "../gallery/Gallery.css";
import images from "../../pics/wild-west/wild-west.js";

export default function WildWest() {
  return (
    <div className="bg-stone-200 flex justify-center">
      <ImageGallery
        items={images}
        showThumbnails={true}
        showFullscreenButton={true}
        showPlayButton={true}
      />
    </div>
  );
}
