import one from "./1.jpg";
import two from "./2.jpg";
import three from "./3.jpg";
import four from "./4.jpg";
import five from "./5.jpg";
import six from "./6.jpg";
import seven from "./7.jpg";
import eight from "./8.jpg";
import nine from "./9.jpg";
import ten from "./10.jpg";
import eleven from "./11.jpg";

const images = [
  {
    original: one,
    thumbnail: one,
  },
  {
    original: two,
    thumbnail: two,
  },
  {
    original: three,
    thumbnail: three,
  },
  {
    original: four,
    thumbnail: four,
  },
  {
    original: five,
    thumbnail: five,
  },
  {
    original: six,
    thumbnail: six,
  },
  {
    original: seven,
    thumbnail: seven,
  },
  {
    original: eight,
    thumbnail: eight,
  },
  {
    original: nine,
    thumbnail: nine,
  },
  {
    original: ten,
    thumbnail: ten,
  },
  {
    original: eleven,
    thumbnail: eleven,
  },
];

export default images;
