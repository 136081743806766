import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/home/Home";
import Gallery from "./pages/gallery/Gallery";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./components/Contact";
import About from "./pages/about/About";
import Footer from "./components/Footer";
import Imprint from "./components/Imprint";
import Africa from "./pages/portfolio/Africa";
import EuropeBirds from "./pages/portfolio/EuropeBirds";
import Sandstones from "./pages/portfolio/Sandstones";
import WatersOfAlps from "./pages/portfolio/WatersOfAlps";
import EuropeWildlife from "./pages/portfolio/EuropeWildlife";
import Salzburg from "./pages/portfolio/Salzburg";
import WildWest from "./pages/portfolio/WildWest";
import Success from "./components/Success";

function App() {
  return (
    <Router>
      <div class="flex flex-col h-screen justify-between">
        <Header />
        <Routes className="flex-grow" >
          <Route exact path="/" element={<Home />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/imprint" element={<Imprint />} />
          <Route exact path="/portfolio/africa" element={<Africa />} />
          <Route exact path="/portfolio/europebirds" element={<EuropeBirds />} />
          <Route exact path="/portfolio/wild-west" element={<WildWest />} />
          <Route exact path="/portfolio/sandstones" element={<Sandstones />} />
          <Route exact path="/portfolio/watersofalps" element={<WatersOfAlps />} />
          <Route exact path="/portfolio/wildlife" element={<EuropeWildlife />} />
          <Route exact path="/portfolio/salzburg" element={<Salzburg />} />
          <Route exact path="/success" element={<Success />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
